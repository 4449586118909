import { PageElement, html, css } from 'Elements';
import './tree.js';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
        }

        m-icon {
          font-size:2em;
        }

        m-icon[name="cached"] {
          color:var(--sl-color-gray-400);
        }

        m-icon[name="cached"]:hover {
          color:var(--sl-color-primary-500);
        }

        sl-button::part(spinner) {
          font-size:1.5em;
        }
      `
    ];
  }

  static get properties() {
    return {
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            refresh:'Refresh',
            add:'Add'
          },
        },
        french:{
          translation: {
            refresh:'Rafraîchir',
            add:'Ajouter'
          }
        }
      }
    ]
  }

  async refresh() {
    this.shadowRoot.querySelector('sl-button[name="refresh"]').loading = true;
    await this.shadowRoot.querySelector('naf-tree').reload();
    this.shadowRoot.querySelector('sl-button[name="refresh"]').loading = false;
  }


  showHelp() {
    this.shadowRoot.querySelector('modal-drawer[name="help"]').show();
  }

  render() {    
    return html`
      <section-header micon="shield_with_house" backroute="../../">
        Organisations externes
        <sl-button slot="right" variant="text" size="small" name="refresh" title="${this._tl('refresh')}" @click="${this.refresh}"><m-icon name="cached"></sl-button>
        <sl-button slot="right" variant="text" size="small" name="help" @click="${this.showHelp}"><m-icon name="help"></sl-button>
      </section-header>
      <br/>
      <external-sos-tree></external-sos-tree>
      <external-sos-node-edit id="editor"></external-sos-node-edit>
      <modal-drawer name="help" label="Aide"></modal-drawer>
      <br/><br/><br/>
    `;
  }

}

customElements.define('page-sys-external-sos', Page);