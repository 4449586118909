import i18next from 'i18next';
import { Session } from 'Utils';
import App from 'App';

function getNodeTypes() {


  const _i18nInstance = i18next.createInstance();
  _i18nInstance.init({
    resources: {
      english:{
        translation: {
          general: 'General',
          properties: 'Properties',
          email: 'Email',
          region:'Region',
        }
      },
      french:{
        translation: {
          general: 'Général',
          properties: 'Propriétés',
          email: 'Email',
          region:'Région',
        }
      }
    },
    fallbackLng: App.config.language,
    interpolation: { escapeValue: false }
  });

  function _tl(key) {
    if (!_i18nInstance) return key;
    const txt = _i18nInstance.t(key);
    if (txt === key) {
      console.warn(`translation not found for key '${key}' '${App.config.language}'`);
      //console.log(JSON.stringify(this._i18nResources, null, 4));
    }
    return txt;
  }


  //const hidden = ENV.match(/prod/) ? 'hidden' : '';
  const hidden = 'hidden';

  const properties = {
    name:'properties',
    title:'Propriétés <m-icon name="lock"></m-icon>',
    hidden:() => !Session.isBoss(),
    fields:[
      { component:'box-json' },
    ]
  }

  const definitions = {
    group:{
      name:'Groupe',
      micon:'folder_open',
      tabs:[
        {
          name:'general', 
          title:'Général',
          fields_hidden:[
            { name:'nodeType',  component:'sl-input',         label:'Type de noeud', sameDefaultValue: true, css: `${hidden}` },
          ],
          fields_lgs:[
            { name:'name',     component:'sl-input',          label:'Nom' },
          ]
        }
      ]
    },
    authority:{
      name:'Autorité locale',
      micon:'local_police',
      noChilds:true,
      tabs:[
        {
          name:'general', 
          title:'Général',
          fields_hidden:[
            { name:'nodeType',  component:'sl-input',         label:'Type de noeud', sameDefaultValue: true, css: `${hidden}` },
          ],
          fields_lgs:[
            { name:'name',     component:'sl-input',          label:'Nom' },
          ]
        }
      ]
    },
    csirt:{
      name:'CSIRT',
      micon:'security',
      noChilds:true,
      tabs:[
        {
          name:'general', 
          title:'Général',
          fields_hidden:[
            { name:'nodeType',  component:'sl-input',         label:'Type de noeud', sameDefaultValue: true, css: `${hidden}` },
          ],
          fields_lgs:[
            { component:'sl-input',               label:'Nom',                          name:'name', },
          ],
          fields:[
            { component:'sl-input',               label:_tl('email'),                   name:'email',             type:'email' },
            { component:'sl-input',               label:_tl('region'),                  name:'region',            },
          ]
        }
      ]
    }
  }

  for (const key in definitions) {
    definitions[key].tabs?.push({ ...properties })
  }

  return definitions;
}

export default { getNodeTypes };